h1,
h2,
h3 h5,
h6 {
	letter-spacing: 1px;
	color: #686868;
	font-family: 'Montserrat';
	font-style: normal;
	margin-top: 0;
	font-weight: 200;
	line-height: 1.4;
}

h4 {
	color: #686868;
	font-family: 'Montserrat';
	font-weight: 500 !important;
	margin-top: 0;
	line-height: 1.4;
}

.nav-item .dropdown-menu {
	max-height: 400px;
	overflow: auto;
}

.hit-main-layout {
	margin-top: 65;
	min-height: 70vh;
}

.mt-header h1 {
	color: white;
	margin: auto;
	padding-top: 67px;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 48px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.mt-header h3 {
	margin: auto;
	text-align: center;
	padding: 10px;
	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-bottom: 10px;
}

.mt-header {
	height: 250px;
	background-color: #607d8b;
	color: white;
}

.mt-header h1 {
	color: white;
	margin: auto;
	padding-top: 67px;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 48px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.mt-header h3 {
	margin: auto;
	text-align: center;
	padding: 10px;
	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 100;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
	margin-bottom: 10px;
}

.mt-body {
	min-height: 500px;
}

.sign-in-box {
	padding: 50px;
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	height: auto;
	margin-top: -50px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 19%);
	border: solid 1px rgba(189, 189, 189, 0.5);
	margin-bottom: 50px;
}

.hit-breadcrumb .breadcrumb {
	margin: 0px;
}

.hit-breadcrumb ol {
	margin-bottom: 0px;
	background: whitesmoke;
	padding: 5px;
	padding-left: 10px;
	border-radius: 5px;
}

.main-section {
	min-height: 75vh;
}

.span-profile-icons {
	display: inline-block;
	border-radius: 60px;
	box-shadow: 0 0 3px #1a6f0196;
	padding: 0.5em 0.6em;
	color: #51ba66;
}

.btn-hit-secondary {
	background-color: #ffa32c;
	border-color: #ffa32c;
	border-radius: 0;
	color: white;
}

.btn-hit-secondary:hover,
.custom-active-btn.btn.btn-hit-secondary {
	background-image: linear-gradient(45deg, #8bca4a 0%, #51ba66 100%);
	border-image: linear-gradient(45deg, #8bca4a 0%, #51ba66 100%);
	color: white !important;
}

.list-group-item-secondary {
	color: #41464b;
	background-color: #f5f5f5;
}

.list-group-item-secondary:hover,
.list-group-item-secondary:focus {
	color: #555;
	text-decoration: none;
	background-color: #f5f5f5;
}

.list-group-item-primary {
	color: #ffffff;
	background-color: #91d465;
}

.navbar {
	background: linear-gradient(335deg, #152B40 0%, #0B1927 100%) !important;
}

.footer-nav__link {
	color: #41a455;
}

.footer-nav__link:hover {
	color: #41a455;
	text-decoration: underline !important;
}

.footer-nav__item:first-child:after {
	content: '';
	position: absolute;
	width: 1px;
	height: 10px;
	background: #939393;
	bottom: 5px;
	right: 1px;
}

.dashboard-main-section {
	min-height: 500px;
}

.PhoneInput {
	padding: 1px;
	background: #d9d9d9;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	outline: none;
}

.PhoneInputInput {
	height: 40px;
	background: #d9d9d9;
	border: none;
	font-family: inherit !important;
	font-weight: 500;
	font-size: 16px;
	color: #0c243f;
	padding-left: 10px;
}

.PhoneInputInput:focus {
	background: #d9d9d9;
	border: none;
	outline: none;
}

.PhoneInputCountryIcon {
	border: none !important;
	width: 38px !important;
	height: 25px !important;
	background: #d9d9d9 !important;
	box-shadow: none !important;
}

.PhoneInputCountry {
	padding-left: 10px !important;
}

.PhoneInputCountryIcon--border {
	background-color: #fff !important;
	box-shadow: none !important;
}

.MuiButton-containedPrimary {
	background: #4caf50 !important;
	font-family: 'Montserrat';
	font-size: 17px;
	font-weight: 700;
}

.MuiButton-containedPrimary:active,
.MuiButton-containedPrimary:hover,
.MuiButton-containedPrimary.custom-active {
	background: #ffa32c !important;
}

.MuiFormHelperText-root.Mui-error {
	color: red !important;
}

.MuiFormHelperText-contained {
	display: none;
}

.MuiFilledInput-root:after,
.MuiFilledInput-root:before {
	border: none !important;
}

.link-hitCheck {
	font-family: 'Montserrat' !important;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	color: #1565c0 !important;
	cursor: pointer;
}

.hitcheck-link-text {
	font-family: 'Montserrat' !important;
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
}
.hitcheck-link-text-blue {
	font-family: 'Montserrat' !important;
	font-weight: 400;
	font-size: 14px;
	color: #95d2ec;
	cursor: pointer;
}
.hitcheck-link-text-blue:hover {
	font-family: 'Montserrat' !important;
	font-weight: 400;
	font-size: 14px;
	color: #95d2ec;
	cursor: pointer;
}

.not-found {
	height: 65.2vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 42px;
	font-weight: 300;
}

label {
	color: silver;
	font-weight: 700;
	font-family: 'Montserrat' !important;
	font-weight: 700 !important;
	font-size: 1rem;
}

.label-error {
	color: red !important;
}

.custom-link {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.29;
	letter-spacing: normal;
	color: #1565c0;
}

.MuiFormLabel-root {
	font-family: 'Montserrat';
	font-size: 0.8rem;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgb(76, 175, 80) !important;
}

.hit-radio-button-group {
	border: 1px solid #d5d8db !important;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	padding-top: 4px !important;
}

.hit-radio-button-group > .MuiFormGroup-row {
	justify-content: space-between !important;
	padding-left: 14px;
}

.hit-radio-button-group > .MuiFormLabel-colorPrimary {
	padding-left: 10px;
}

.MuiFormControlLabel-root {
	flex-grow: 1;
}

.hit-radio-button-group > .MuiFormLabel-colorPrimary {
	font-size: 12px;
}

.MuiRadio-root.Mui-checked {
	color: #4caf50;
}

.MuiFormControlLabel-label {
	color: #323232;
}

.MuiCheckbox-root > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
	color: #4caf50;
}

.MuiFilledInput-root {
	background: white !important;
	border: 1px solid #d5d8db !important;
	font-weight: 400 !important;
}

.MuiFormHelperText-root {
	display: none;
}

.MuiFormLabel-filled .Mui-Focused {
	font-family: 'Montserrat' !important;
	color: silver;
	font-weight: 700 !important;
}

.MuiInputLabel-shrink.MuiInputLabel-root.Mui-error,
.MuiInputLabel-root.Mui-error {
	font-weight: 500;
	color: red !important;
}

.MuiInputLabel-filled {
	color: silver !important;
}

.MuiFormLabel-filled:focus,
.MuiInputLabel-shrink,
.MuiFormLabel-filled.active {
	font-family: 'Montserrat' !important;
	font-weight: 700 !important;
	color: #4caf50 !important;
}

.MuiInputLabel-root.Mui-focused {
	color: silver !important;
	font-weight: 500;
}

/* .MuiFilledInput-input {
  color: #2b2b2b !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth {
  border: 1px solid #d5d8db;
  padding-top: 5px;
} */

.MuiButtonBase-root.MuiRadio-root.Mui-checked {
	color: #4caf50 !important;
}

.MuiButtonBase-root.MuiRadio-root {
	color: #d5d8db !important;
}

/* .list-group-item-info {
	color: #31708f;
	background-color: #d9edf7;
} */

.list-group-item-primary:hover,
.list-group-item-primary:focus {
	color: #ffffff;
	background-color: #91d465;
}

a.list-group-item:hover,
a.list-group-item:focus {
	color: #555;
	text-decoration: none;
	background-color: #f5f5f5;
}

.list-group-item {
	border-bottom: 1px solid #003f60;
	padding: 9px 15px;
	color: #0c243f;
	font-weight: 500;
	font-size: 14px;
}

.list-group-item:last-child {
	border-bottom: none;
}

.list-group-item-action.list-group-item-primary {
	background-color: #91d465;
	color: #ffffff;
	font-weight: 700;
	font-size: 14px;
}

.list-group-item-action.list-group-item-primary:hover,
.list-group-item-action.list-group-item-primary:focus {
	background: #91d465;
	color: #ffffff;
}

.MuiPickersDay-root.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected {
	background-color: #4caf50 !important;
}

.dropzone {
	text-align: center;
	padding: 30px;
	border: 1.5px #91d465 dashed;
	margin: auto;
	border-radius: 3px;
	background-color: #f5f5f5;
}

.dropzone-ondrop {
	display: block;
	padding: 46px 30px;
}

.dropzone-error {
	text-align: center;
	padding: 75px 30px;
	border: 2px #e23232 dashed;
	margin: auto;
	border-radius: 3px;
}

.dropzone-content {
	font-family: 'Montserrat';
	color: #0c243f;
}

.drag-drop-icon {
	height: 120px;
	width: 120px;
}

.selected-avatar {
	height: 150px;
}

/* .main-section {
	min-width: 1400px;
} */

.custom-tab {
	margin: 10px 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
	cursor: pointer;
}

.custom-tab-title {
	line-height: 1.6;
	text-align: left;
	text-transform: uppercase;
	margin: 0;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: bold;
	color: #003f60;
}

.custom-tab-sub-title {
	font-size: 12px;
	line-height: 1;
	font-weight: 600;
	font-family: 'Montserrat';
	color: #86a1ad;
	margin: 0;
}

.best-baseline-tab {
	/* margin: 0px 10px; */
	padding: 10px;
	background: #ffffff;
	border-bottom: 1px solid #003f60;
}

.best-baseline-tab .MuiTabs-indicator {
	background: #91d465 !important;
}

.best-baseline-tab .MuiButtonBase-root.Mui-selected {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: #f5f5f5;
	border-bottom-color: transparent;
	line-height: 0.5;
	color: #555;
	cursor: default;
}

.best-baseline-tab .MuiButtonBase-root {
	line-height: 0.5;
	padding: 5px 5px;
	color: #555;
	cursor: default;
	background-color: #fff;
}

.graph-card-date {
	font-size: 12px;
	line-height: 0;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	font-family: 'Montserrat';
	color: #86a1ad;
	margin: 0;
}

.graph-name {
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #003f60;
}

.orange-legend {
	height: 15px;
	width: 15px;
	background-color: #fa9d37;
	margin-right: 8px;
}

.red-legend {
	height: 15px;
	width: 15px;
	background-color: #e82713;
	margin-right: 8px;
}

.legal-note {
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
}

.legal-note-grey {
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #7a7a7a;
}

.color-green,
.baseline-color {
	color: #7fc750 !important;
}

.color-red,
.danger-color {
	color: #e82713 !important;
}

.color-sky-blue {
	color: #30b1e8;
}

.color-orange,
.warning-color {
	color: #fa9d37 !important;
}

.negative-color {
	color: #ff5722 !important;
}

.neutral-color,
color-gray {
	color: rgb(96, 125, 139) !important;
}

.color-green .MuiLinearProgress-bar,
.baseline-color .MuiLinearProgress-bar {
	background-color: #91d465 !important;
	color: #91d465 !important;
}
.color-sky-blue .MuiLinearProgress-bar {
	background-color: #30b1e8 !important;
	color: #30b1e8 !important;
}

.danger-color .MuiLinearProgress-bar {
	background-color: rgb(232, 39, 19) !important;
	color: #e82713 !important;
}

.negative-color .MuiLinearProgress-bar {
	background-color: #ff5722 !important;
	color: #ff5722 !important;
}

.warning-color .MuiLinearProgress-bar {
	background-color: #fa9d37 !important;
	color: #fa9d37 !important;
}

.neutral-color .MuiLinearProgress-bar {
	background-color: #30b1e8 !important;
	color: #30b1e8 !important;
}

.color-grey {
	color: #d9d9d9;
}

#test-scores-wrapper .css-5hvl7w {
	padding: 25px 25px 0px 25px !important;
}

.bar-label {
	align-self: center;
	width: 110px;
	margin-left: 5px;
	margin-right: 5px;
	/* background-color: wheat; */
}

.graph-divider {
	height: 1px;
	width: 75%;
	background: #0c243f !important;
}

.empty-bar {
	flex-grow: 1;
	/* background-color: #454545; */
}

.bar-report-text {
	display: flex;
	justify-content: end;
}

.graph-tooltip {
	min-width: 100px;
	display: flex;
	flex-grow: 1;
	align-self: center;
	justify-content: center;
	padding: 5px;
	padding-bottom: 0px;
	/* background-color: #31708f; */
}

.tooltip-numerator-text {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
	flex-grow: 1;
	text-align: center;
}

.tooltip-denominator-text {
	color: rgb(117, 117, 117) !important;
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
	flex-grow: 1;
}

.graph-linear-bar {
	display: flex;
	align-items: center;
}

.numerator-text {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
	color: #003f60;
}

.denominator-text {
	color: #003f60 !important;
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 500;
}

.test-score-date-text {
	min-width: 100px;
	font-weight: bold;
	font-size: 13px;
	text-align: right;
	vertical-align: middle;
}

.test-score-text {
	min-width: 100px;
	font-weight: bold;
	font-size: 13px;
	color: #003f60;
	text-align: left;
	vertical-align: middle;
}

/* .linear-bar-container {
	border-color: #0C243F !important;
	border-bottom-width: 1px !important;
	border-bottom: 1px solid #0c243f !important; 
	 padding-bottom: 25px;
} */

.test-score-bar-container {
	flex-grow: 1;
	display: 'flex';
	align-items: 'center';
	/* border-bottom: 1px solid #0c243f; */
}

.test-result-selector .MuiToggleButton-root {
	line-height: 1;
	letter-spacing: normal;
	width: 150px;
	font-size: 16px !important;
}

.nsi-test-column {
	line-height: 1.5;
	width: 130px;
	text-align: center;
	min-height: 50px;
	font-size: 12px;
	font-weight: 600;
}

.nsi-group-header-label {
	color: #fff;
	background: #072F4C;
	padding: 6px 15px;
	border-bottom: 1px solid #fff;
	border-collapse: collapse;
	min-width: 250px;
	text-align: left;
	font-size: 15px;
	font-family: Montserrat-Medium;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nsi-group-header-label .expand-collapse-container {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.nsi-group-header-value {
	color: #0C243F;
	background: #ECECEC;
	padding: 6px 15px;
	border-right: 1px solid #FFF; 
	border-bottom: 1px solid #FFF; 
	border-collapse: collapse;
	min-width: 50px;
	text-align: center;
	font-size: 15px;
	font-family: Montserrat-Medium;
}

.nsi-symptom-header-label {
	color: #0C243F;
	background: #ECECEC;
	padding: 6px 15px;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #FFF;
	border-collapse: collapse;
	min-width: 250px;
	text-align: left;
	font-size: 14px;
	display: flex;
	align-items: center;
	font-family: Montserrat-Medium;
}

.nsi-symptom-icon {
	width: 25px;
	margin-right: 10px;
}

.nsi-symptom-header-value {
	color: #0C243F;
	background: #FFF;
	padding: 6px 15px;
	border-bottom: 1px solid #ECECEC; 
	border-right: 1px solid #ECECEC; 
	border-collapse: collapse;
	min-width: 50px;
	font-size: 14px;
	text-align: center;
	font-family: Montserrat-Medium;
}

.nsi-total {
	background: #072F4C;
	color: #fff;
}

.symptoms-score-reference {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.symptoms-score-reference table {
	border: 1px solid #0C243F; 
	border-collapse: collapse;
	overflow: scroll;
	padding-bottom: 5px;
}

.symptoms-score-reference .scoring-header {
	background: linear-gradient(133.34deg, #0C243F 24.1%, #003F60 82.5%);
	color: #FFF;
	text-align: center;
	border: 1px solid #0C243F; 
	padding: 5px 10px;
	font-family: Montserrat-Medium;
}

.symptoms-score-reference .symptoms-header {
	background-color: #ECECEC;
	color: #0C243F;
	padding: 5px 10px;
	border: 1px solid #0C243F; 
	border-collapse: collapse;
	text-align: center;
	font-family: Montserrat-Medium;
	min-width: 110px;
}

.symptoms-score-reference .symptoms-value {
	background-color: #FFF;
	color: #0C243F;
	padding: 5px 10px;
	border: 1px solid #0C243F; 
	border-collapse: collapse;
	text-align: center;
	font-family: Montserrat-Medium;
}

.symptoms-results ::-webkit-scrollbar {
	height: 5px;
	width: 5px;
}

.symptoms-results ::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px;
}

@media screen and (max-width: 992px) {

	.test-result-selector .MuiToggleButtonGroup-root {
		margin: 0px !important;
	}
}

.date-text {
	color: #003f60 !important;
	font-family: 'Montserrat';
	font-size: 10px;
	font-weight: bold;
	min-width: 100px;
}

.panel-baseline-graph {
	/* padding: 8px 15px; */
	/* margin-bottom: 20px; */
	list-style: none;
	/* border-radius: 4px; */
	/* border: 1px solid #ddd; */
	/* background-color: #f5f5f5; */
}

.green-tick-fab {
	background-color: #91d465 !important;
	color: white !important;
	width: 35px !important;
	height: 30px !important;
}

.red-tick-fab-icon {
	background-color: red !important;
	color: white !important;
	width: 20px !important;
	height: 20px !important;
}

.custom-tab-sub-title-2 {
	font-size: 11px;
	line-height: 1.5;
	font-weight: 600;
	font-family: 'Montserrat';
	text-align: left;
	color: #455a64 !important;
	margin: 0;
	text-transform: capitalize;
}

.custom-tab-title-2 {
	line-height: 1.6;
	text-align: left;
	text-transform: uppercase;
	margin: 0;
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: bold;
	color: #455a64 !important;
}

.sky-blue-check-icon {
	color: white;
	background: #30b1e8;
	border-radius: 13px;
	display: inline-block;
	padding: 3px;
	font-weight: bolder;
}
.green-check-icon {
	color: white;
	background: #91d465;
	border-radius: 13px;
	display: inline-block;
	padding: 3px;
	font-weight: bolder;
}

.MuiButtonBase-root.test-sub-tab {
	padding: 0;
	border-radius: 8px !important;
	padding-left: 10px !important;
	background-color: #ffffff !important;
}

.MuiButtonBase-root.test-sub-tab.Mui-selected .custom-tab-title-2,
.MuiButtonBase-root.test-sub-tab.Mui-selected .custom-tab-sub-title-2 {
	color: #003f60 !important;
}

.MuiButtonBase-root.test-sub-tab.Mui-selected {
	color: #003f60 !important;
	background: #f5f5f5 !important;
}

.MuiButtonBase-root.test-sub-tab.Mui-selected:hover .custom-tab-title-2,
.MuiButtonBase-root.test-sub-tab.Mui-selected:hover .custom-tab-sub-title-2,
.MuiButtonBase-root.test-sub-tab:hover {
	background-color: white !important;
	color: #003f60 !important;
}

.sub-test-tabs {
	background: #ffffff !important;
}

.dropdown-toggle {
	font-size: 14px;
	font-family: 'Montserrat';
	text-transform: uppercase;
	font-weight: 400;
	color: #676a69;
}

.dropdown-item {
	font-size: 14px;
	font-family: 'Montserrat';
	text-transform: uppercase;
	font-weight: 300;
	color: #676a69;
	line-height: 2;
}

.dropdown-item:hover {
	font-family: 'Montserrat';
	text-transform: uppercase;
	font-weight: 300;
	color: #676a69;
	background-color: #f5f5f5;
}

.cell-buttons-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	min-width: fit-content;
}

.team-title {
	margin-bottom: 20px;
}

.team-edit-container {
	display: inline-block;
}

.edit-link {
	margin-left: 10px;
	font-size: small;
}

.table-header-text-name {
	min-width: 150px;
}

.table-header-text-baseline {
	min-width: 100px;
}

/* team dashboard */

.panel-default {
	border-color: #0c243f;
}

.panel {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
	overflow: auto;
	margin-bottom: 20px;
}

.panel-heading {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	border-bottom: 1px solid #003f60;
}

.pull-right {
	float: right !important;
}

.panel-default > .panel-heading {
	color: #ffffff;
	background-color: #f5f5f5;
}

.table {
	width: 100%;
	max-width: 100%;
	border-top: 1px solid transparent;
	border-collapse: collapse;
	font-size: 14px;
	color: #333;
	text-indent: initial;
	margin: 0;
}

thead,
tbody {
	display: table-header-group;
	vertical-align: middle;
	border-color: inherit;
	border-top: 1px solid transparent;
}

tr {
	display: table-row;
	vertical-align: inherit;
	border-color: inherit;
}

td {
	vertical-align: middle;
	border: 1px solid #ddd;
}

.table-actions-container {
	display: flex;
	justify-content: flex-start;
}

.btn-sm {
	padding: 5px 10px;
	font-size: 12px !important;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.btn {
	display: inline-block;
	margin-right: 8px;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.join-code-icon {
	margin-left: 10px;
}

a {
	color: #428bca;
}

.list-group-item a:hover {
	color: #428bca;
	text-decoration: underline !important;
}

.basline-icon-green {
	margin-right: 8px;
	color: #00ba00;
}

.basline-icon-red {
	margin-right: 8px;
	color: #ff4743;
}
.basline-icon-orange {
	margin-right: 8px;
	color: #ffa32c;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.select-label {
	top: 15px;
}

.container-space-between {
	display: flex;
	justify-content: space-between;
}

.table-header-text {
	display: flex;
}

.clickable-icon {
	cursor: pointer;
}

.align-Item-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* New Ui Styles */

.dashboard-card-title {
	color: #ffffff;
	line-height: normal;
	font-weight: 700;
	font-size: 54px;
	line-height: 66px;
	margin: 0;
}

.title-type-text {
	display: inline;
	color: #91d465;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	cursor: pointer;
}

.dashboard-flex-between-container {
	padding-top: 3rem;
	padding-bottom: 3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dashboard-card-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
}

.dashbord-edit {
	color: #95d2ec;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	cursor: pointer;
}

.edit-icon {
	margin: 0 5px 5px 10px;
	height: 20px;
	width: 20px;
}

.team-widget-col {
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
}

.full-width-container {
	display: flex;
	width: max-content;
}

.test-percentile-card-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
}

.percentile-card-value-tile-container {
	display: flex;
	align-items: center;
}

.test-percentile-card-value {
	color: #ffffff;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	min-width: 40px;
}

.test-percentile-card-title {
	width: 150px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
	margin-left: 10px;
	align-items: center;
}

.roster-card-Container {
	display: flex;
	flex-direction: column;
	margin: 15px;
	align-items: center;
	justify-content: center;
}

.upload-roster-instructions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #F65A4A;
	color: #fff;
	line-height: 1.5;
}

.widget-join-code {
	display: flex;
	align-items: flex-start;
	width: 175px;
	font-weight: 500;
	font-size: 16px;
	color: #91d465;
}

.widget-code-name-container {
	display: flex;
	flex-direction: row;
	max-width: 175px;
	align-items: center;
	margin-bottom: 10px;
}
.widget-code-name {
	display: flex;
	width: 140px;
	margin: 5px 0 5px 0;
  padding-bottom: 3px;
	align-items: flex-start;
	font-weight: 600;
	font-size: 20px;
	color: #003f60;
	overflow-x: auto;
}

.widget-code-name::-webkit-scrollbar {
	height: 4px;
}

.widget-code-name::-webkit-scrollbar-track {
	border-radius: 4px;
	width: 25%;
	background: #4b99be40;
}

.widget-code-name::-webkit-scrollbar-thumb {
	background-color: #95d2ec;
	border-radius: 4px;
	border: 1px solid transparent;
}

.widget-container {
	margin: 25px 0;
}

.widget-pie-container {
	display: flex;
	/* flex-direction: row; */
	justify-content: center;
	align-items: center;
	margin: 10px;
}

@media screen and (max-width: 800px) {
	.widget-pie .baseline-value {
		font-size: 40px;
		font-weight: 700;
		align-items: center;
	}
	.widget-pie-container .widget-pie {
		justify-content: center;
		align-items: center;
	}

	.widget-pie .widget-baseline-text {
		max-width: 150px;
		text-align: center;
	}

	.red-legend, .orange-legend {
		width: 30px;
		height: 20px;
	}
}
.pie-chart {
	width: fit-content;
	height: fit-content;
}

.widget-pie {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.baseline-icon {
	width: 80px;
	height: 80px;
}

.baseline-value {
	display: inline-block;
	font-weight: 700;
	font-size: 60px;
	color: #003f60;
	margin-left: 5px;
}

.widget-baseline-text {
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	color: #003f60;
	margin-left: 5px;
}

.align-space-around {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.table-container {
	margin: 25px 0 25px 0;
	padding: 0;
}

.widget-row {
	border-collapse: collapse;
	align-items: center;
}

.league-widget-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}
.league-widget-card-icon {
	width: 60px;
	height: 60px;
	margin-bottom: 10px;
}

.league-widget-card-value {
	display: inline-block;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	text-align: center;
	color: #003f60;
}

.league-widget-card-text {
	padding-top: 5px;
	display: inline-block;
	vertical-align: middle;
	min-height: 50px;
	font-weight: 600;
	font-size: 11px;
	text-align: center;
	color: #003f60;
	max-width: 95px;
}

/* .table-dropdown:hover {
	background-color: #ffffff;
} */

.table-dropdown > .dropdown-toggle:hover {
	background-color: #95d2ec;
}

.table-dropdown .dropdown-toggle {
	min-width: 100px;
	max-height: 23px;
	border-radius: 25px;
	border: 1px solid #003f60;
	color: #003f60;
	font-weight: 500;
	font-size: 10px;
	line-height: 10px;
	text-align: center;
	background-color: #ffffff;
}

.table-dropdown .table-dropdown-menu {
	background: #ffffff;
	border: 1px solid #003f60;
	border-radius: 2px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 0;
}

.table-dropdown-menu .table-dropdown-item {
	border-bottom: 1px solid #003f60;
	font-weight: 500;
	font-size: 12px;
	color: #003f60;
}
.table-dropdown-menu .table-dropdown-item:last-child {
	border-bottom: none;
}

.table-dropdown .dropdown-toggle:after {
	display: none !important;
}

.user-acceptance-container {
	padding: 6px 0;
	background-color: #f65a4a;
	align-items: center;
	justify-content: center;
}

.alert-icon {
	width: 17px;
	height: 17px;
}

.awaiting-approval-text {
	color: #ffffff;
	font-weight: 500;
	font-size: 13px;
	vertical-align: middle;
	padding: 0 25px 0 10px;
}

.modal-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #ffffff;
}

.confirm-action-text {
	display: inline-block;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #f65a4a;
	margin-bottom: 15px;
}

.modal-sub-title {
	display: block;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #003f60;
	margin-bottom: 5px;
}

.modal-text {
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
	color: #003f60;
	margin-bottom: 15px;
}

.modal-text-bold {
	font-weight: 700;
	font-size: 15px;
}

.modal-icon {
	width: 150px;
	height: 100px;
	margin-right: 55px;
}

.modal-button-container {
	display: flex;
	justify-content: flex-end;
	padding: 0 55px;
}

@media only screen and (max-width: 800px) {
	.modal-button-container {
		justify-content: space-between;
		padding: 0 25px 0 15px;
	}
}

.search-container {
	margin: 30px 0;
}

.search-box-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0 30px;
	max-width: 1000px;
	margin: 0 auto;
}
.search-filter {
	margin-left: 135px;
}

@media only screen and (max-width: 800px) {
	.search-filter {
		margin-left: 0;
	}
}

.search-icon {
	width: 30px;
	height: 30px;
}

.search-box {
	width: 100%;
	border: 1px solid #003f60;
	border-radius: 0px;
	font-weight: 400;
	font-size: 14px;
	font-family: 'Montserrat';
	line-height: 17px;
	color: #003f60;
}

.search-form {
	width: 100%;
	margin: 0 12px;
}
.search-box::placeholder {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #95d2ec;
}

.search-form .search-checkbox-label {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #91d465;
	margin: 10px 20px 0 0;
}

#search-checkbox-1,
#search-checkbox-2,
#search-checkbox-3,
.change-role-checkbox {
	padding: 0;
	border-radius: 0;
	border: 1px solid #0c243f;
}

#search-checkbox-1:checked,
#search-checkbox-2:checked,
#search-checkbox-3:checked,
#change-role-checkbox:checked {
	background: #91d465;
}

.search-form .form-check-label {
	font-weight: 500;
	font-size: 12px;
	color: #003f60;
}

.change-role-radio .MuiButtonBase-root.MuiRadio-root.Mui-checked {
	color: black;
}

.change-role-form {
	display: flex;
	flex-direction: column;
}

.change-role-form .form-check-label {
	font-size: 14px;
	color: #003f60;
}

.baseline-reminder-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #003f60;
}

.dropdown-select-label {
	font-size: 12px !important;
	margin-top: 15px;
}

.dropdown-arrow-icon {
	width: 22px;
	height: 9px;
}

.app-min-hight {
	min-height: 90vh;
}

/* Super admin */
.table-btn {
	background-color: #91d465;
	border-color: #91d465;
	border-radius: 2px;
	font-size: 12px;
	padding: 2px 6px;
}

.table-btn:hover {
	background-color: #91d465 !important;
	border-color: #91d465;
	color: #003f60 !important;
}

.pagination-button {
	border: none;
	border-radius: 2px;
	padding: 2px 8px;
	background-color: #ffffff;
	color: #003f60;
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
}

.pagination-button:disabled {
	background-color: #d9d9d9;
	color: #003f60;
}

.panel-container {
	border: 1px solid #003f60;
	border-radius: 2px;
	align-items: center;
}

.pagination-container strong {
	color: #7fc750;
	font-size: 12px;
}

.pagination-container span {
	color: #ffffff;
	font-size: 12px;
}

.pagination-container input {
	color: #0c243f;
	max-width: 50px;
	font-size: 12px;
	line-height: 16px;
	padding: 2px 4px;
	border: none;
	border-radius: 2px;
	justify-content: center;
}
.pagination-container input:focus {
	outline: none;
}

.pagination-container select {
	margin-left: 10px;
	color: #0c243f;
	font-size: 12px;
	line-height: 16px;
	padding: 2px;
	border: none;
	border-radius: 2px;
}

.pagination-container select {
	outline: none;
}

.red {
	color: #cc0000;
}

.green {
	color: #009900;
}

@media only screen and (min-width: 600px) {
	.admin-user-search {
		width: 500px;
	}
}

.admin-user-search {
	display: inline-block;
	font-size: 14px;
}

.admin-user-search-button {
	font-size: 14px !important;
}

@media only screen and (max-width: 600px) {
	.admin-user-search {
		width: 200px;
	}
}

.admin-table td p {
	margin-bottom: 5px;
	color: #003f60;
}

.admin-table th {
	border-bottom: 1px solid #003f60;
	border-right: 1px solid #003f60;
	border-left: none;
	border-top: none;
}

.admin-table td {
	border-right: 1px solid #003f60;
	color: #003f60;
}

.admin-table td:nth-of-type(odd) {
	color: #003f60 !important;
}

.admin-table tr {
	border-bottom: 1px solid #003f60;
}
.admin-table tr:last-child {
	border-bottom: none;
}

.admin-table th:last-child {
	border-right: none;
}

.admin-table td:last-child {
	border-right: none;
}

.admin-view-table tr {
	border-bottom: 1px solid #003f60;
	border-left: none;
	border-top: none;
	border-right: none;
}

.admin-view-table tr:last-child {
	border: none;
}

.admin-view-table td p {
	color: #003f60;
}

.admin-view-table td {
	color: #003f60;
	border-right: 1px solid #003f60;
	border-left: none;
	border-top: none;
	border-bottom: none;
}

.admin-view-table td:nth-of-type(odd) {
	color: #003f60 !important;
}

.admin-view-table td:last-child {
	border-right: none;
}

.pagination-container {
	display: flex;
	background: linear-gradient(133.34deg, #0c243f 24.1%, #003f60 82.5%);
	justify-content: flex-end;
}

.f-14 {
	font-size: 14px;
}

.main-gradient-section {
	min-height: 75vh;
	background: linear-gradient(133.34deg, #0c243f 24.1%, #003f60 82.5%);
}

.list-group-container {
	border-radius: 2px;
	border: 1px solid #003f60;
	border-collapse: collapse;
}

.list-group-gradient-head {
	background: linear-gradient(133.34deg, #0c243f 24.1%, #003f60 82.5%);
	color: #ffffff;
	font-weight: 700;
	font-size: 14px;
}

.list-group-gradient-head:hover {
	color: #ffffff;
}

.list-group-item-container {
	border-bottom: 1px solid #003f60;
}

.form-title {
	display: block;
	text-align: center;
	font-weight: 600;
	font-size: 22px;
	color: #ffffff;
}

.form-back-text {
	display: block;
	font-weight: 500;
	font-size: 16px;
	color: #95d2ec;
	text-align: right;
}

.form-back-text:hover {
	color: #95d2ec;
}

.form-control-radio-button-group {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	padding-top: 4px !important;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label
	> .MuiFormControlLabel-label {
	color: #ffffff !important;
	font-family: 'Montserrat' !important;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label {
	padding-left: 30px;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label:first-child {
	padding-left: 0px;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label-blue
	> .MuiFormControlLabel-label {
	color: #0c243f !important;
	font-family: 'Montserrat' !important;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label-blue {
	padding-left: 30px;
}

.form-control-radio-button-group
	> .form-control-radio
	> .form-control-radio-label-blue:first-child {
	padding-left: 0px;
}

.form-control-radio-button-group > .MuiFormGroup-row {
	justify-content: space-between !important;
	padding-left: 14px;
}

.form-control-radio-button-group > #gender-label {
	color: #ffffff !important;
	font-weight: 400 !important;
	font-size: 16px;
	padding-bottom: 10px;
}
.form-control-radio-button-group > #gender-label-green {
	color: #7fc750 !important;
	font-weight: 600 !important;
	font-size: 14px;
	padding-bottom: 10px;
}

.btn-form-secondary {
	margin-right: 0px;
	background-color: #91d465;
	border-color: #91d465;
	border-radius: 12px;
}
.btn-form-secondary > .button-text-sm {
	vertical-align: middle;
	color: #ffffff;
	font-weight: 600;
	font-size: 12px;
	word-wrap: break-word;
}

.btn-form-secondary > .button-text {
	vertical-align: middle;
	color: #0c243f;
	font-weight: 700;
	font-size: 16px;
	word-wrap: break-word;
}
.btn-form-secondary > .button-text-white {
	vertical-align: middle;
	color: #ffffff;
	font-weight: 700;
	font-size: 16px;
	word-wrap: break-word;
}

.btn-form-secondary:hover,
.custom-active-btn.btn.btn-form-secondary {
	background-color: #91d465 !important;
	color: white !important;
	border-color: #91d465;
}

.profile-text-label {
	font-weight: 500;
	font-size: 18px;
	color: #003f60;
	min-width: 100px;
}

.profile-text-label-white {
	font-weight: 500;
	font-size: 18px;
	color: #ffffff;
}

.profile-text {
	font-weight: 500;
	font-size: 18px;
	color: #91d465;
}

.border-text-field-label {
	font-weight: 600 !important;
	font-size: 14px !important;
	color: #7fc750 !important;
	padding-bottom: 5px !important;
}
.border-text-field-label-white {
	font-weight: 400 !important;
	font-size: 14px !important;
	color: #ffffff !important;
	padding-bottom: 5px !important;
}

.profile-action-instruction {
	font-weight: 500;
	font-size: 16px;
	color: #0c243f;
	font-family: 'Montserrat';
}

.intruction-text-green {
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	color: #91d465;
}

.intruction-text {
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	color: #ffffff;
}

.order-summary-container {
	border: 1px solid #ffffff;
	border-radius: 25px;
}

.plan-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 15px;
	width: 350px;
	min-height: 350px;
	border-width: 4px;
	border-style: solid;
	border-image: linear-gradient(to right, #0c243f, #003f60) 1;
	border-bottom: none;
	border-left: none;
	border-right: none;
	background-color: #f5f5f5;
}

.plan-title {
	display: inline-block;
	padding: 5px 0 10px 0;
	font-weight: 700;
	font-size: 20px;
	color: #003f60;
}

.plan-content {
	font-weight: 500;
	font-size: 14px;
	color: #0c243f;
}

.plan-content-strong {
	font-weight: 500;
	font-size: 14px;
	color: #7fc750;
}

