.pricing-body {
	color: #fff;
	text-align: center;
	margin-bottom: 40px;
}

.pricing-body .header {
	margin-top: 80px;
	margin-bottom: 40px;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	text-align: center;
	color: #ffffff;
}

.solo-signup {
	color: #91d465;
}

.hit-app-icon {
	width: 150px;
	height: 42px;
}

h6 {
	color: #fff !important;
	font-weight: bold !important;
}

.MuiToggleButtonGroup-root {
	background-color: rgba(255, 255, 255, 0.2) !important;
	padding: 0 !important;
	width: fit-content !important;
	border-radius: 68px !important;
}

.MuiToggleButton-root {
	padding: 5px 30px !important;
	color: #fff !important;
	font-size: 20px !important;
	font-weight: 400 !important;
	border: 0px !important;
	text-transform: unset !important;
}

.MuiToggleButtonGroup-root .Mui-selected,
.MuiToggleButtonGroup-root .Mui-selected:hover {
	background-color: #91d465 !important;
	color: #0c243f !important;
	font-weight: 700 !important;
	border-radius: 68px !important;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root {
  font-family: 'Montserrat' !important;
}

/* tier-pricing styles */

.tier-pricing-container {
	border-top-left-radius: 45px;
	border-top-right-radius: 45px;
	overflow: hidden;
}

.pricing-body .hit-app-icon {
	width: 150px;
	height: 42px;
}

.plan-details-container div {
	border-right: 1px solid #607d8b;
}

.plan-details-container div:last-child {
	border-right: none;
}

.tier-pricing-input {
	width: 170px;
	height: 40px;
	border: none;
	outline: none;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #ffffff;
	padding: 4px 10px;
	text-align: right;
  font-family: Montserrat-Bold !important;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 20px 0px 0px 20px;
}

.pricing-body h6 {
	font-weight: 600;
	font-size: 20px;
	line-height: 34px;
	text-align: center;
	color: #ffffff;
}

.member-count-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
}

.member-count-text {
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	text-align: right;
	color: #ffffff;
}

.tier-pricing-input::-webkit-outer-spin-button,
.tier-pricing-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.plan-card-container {
	display: flex;
	/* min-height: 275px; */
	padding-bottom: 30px;
	flex-direction: column;
	justify-content: flex-start;
	background: rgba(0, 0, 0, 0.2);
	align-items: center;
}

.container-background-lite {
	background: rgba(75, 153, 190, 0.25) !important;
}

.plan-card-container .popular {
	width: 100%;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	color: #003f60;
	background-color: #95d2ec;
	padding: 4px 0;
}

.plan-card-container .title {
	padding-top: 20px;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: #ffffff;
}

.plan-card-container .cost {
	padding-top: 10px;
	font-weight: 700;
	font-size: 40px;
	line-height: 49px;
	text-align: center;
	color: #91d465;
}
.plan-card-container .term {
	padding-top: 5px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
}
.plan-card-container .total {
	min-height: 37px;
	padding-bottom: 15px;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #95d2ec;
}
.plan-card-container .custom-plan-text {
	max-width: 240px;
	padding: 15px 20px 35px 20px;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #91d465;
}

@media screen and (max-width: 1200px) {
	.plan-card-container .custom-plan-text {
		padding: 15px 10px 35px 10px;
	}
}

.plan-card-container .custom-plan-sub-text {
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #ffffff;
}

.plan-card-container .header-text {
	display: block;
	margin-top: 12px;
	font-style: italic;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
}

.plan-card-container .header-sub-text {
	max-width: 240px;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	color: #ffffff;
}

.popular-plan {
	border-right: 3px solid #95d2ec !important;
	border-left: 3px solid #95d2ec !important;
}

.popular-plan-mobile-view {
	border-right: 3px solid #95d2ec !important;
	border-left: 3px solid #95d2ec !important;
	border-bottom: 3px solid #95d2ec !important;
}

.popular-plan-border {
	border-right: none;
}

.tier-pricing-container .feature-row {
	border-bottom: 1px solid #003f60;
}

.tier-pricing-container .feature-row div {
	border-right: 1px solid #003f60;
}

.tier-pricing-container .feature-row div:last-child {
	border-right: none;
}

.tier-pricing-container .header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #eeeeee;
	align-items: center;
}

.tier-pricing-container .header .header-text {
	display: block;
	margin-top: 12px;
	font-style: italic;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #0c243f;
}

.tier-pricing-container .header .header-sub-text {
	display: block;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #0c243f;
	margin: 0;
	margin-bottom: 12px;
}

.tier-pricing-container .plan-feature-cell {
	display: flex;
	align-items: center;
	justify-content: left;
	background: #ffffff;
}

.tier-pricing-container .plan-cell {
	display: flex;
	height: auto;
	align-items: center;
	justify-content: center;
	background: #ffffff;
}

.tier-pricing-container .plan-cell .check-icon {
	width: 25px;
	height: 25px;
}

.tier-pricing-container .plan-cell .dash {
	font-size: 20px;
	color: #0c243f;
}

.tier-pricing-container .plan-cell .feature {
	font-weight: 400;
	font-size: 15px;
	color: #0c243f;
}

.tier-pricing-mobile-container .features-container {
	display: flex;
	background: #eeeeee;
	padding: 4px 10px;
	justify-content: center;
}

.tier-pricing-mobile-container .features-container .features-text {
	display: inline-flex;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	color: #0c243f;
	padding: 0px 10px;
	align-items: center;
}
.tier-pricing-mobile-container .features-container .features-button {
	height: 22px;
	width: 22px;
	display: flex;
	background: #91d465;
	font-weight: 700;
	font-size: 14px;
	text-align: center;
	color: #0c243f;
	padding: 0 8px;
	border-radius: 22px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.plan-features-table-mobile:first-child {
	border-top: 1px solid #003f60;
}

.plan-features-table-mobile .feature-row {
	border-bottom: 1px solid #003f60;
}

.plan-features-table-mobile .feature-row div {
	border-right: 1px solid #003f60;
}

.plan-features-table-mobile .feature-row div:last-child {
	border-right: none;
}

.plan-features-table-mobile .plan-feature-cell {
	display: flex;
	align-items: center;
	justify-content: left;
	background: #ffffff;
}

.plan-features-table-mobile .plan-cell {
	display: flex;
	padding: 12px 16px;
	align-items: center;
	justify-content: center;
	background: #ffffff;
}

.plan-features-table-mobile .plan-cell .check-icon {
	width: 25px;
	height: 25px;
}

.plan-features-table-mobile .plan-cell .dash {
	font-size: 20px;
	color: #0c243f;
}

.plan-features-table-mobile .plan-cell .feature {
	font-weight: 400;
	font-size: 15px;
	color: #0c243f;
}

.plan-features-table-mobile {
	border-top: 1px solid #003f60;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
}

.contact-container .contact-text {
	font-weight: 600;
	font-size: 24px;
	text-align: center;
	color: #ffffff;
	margin: 0 15px 30px 15px;
}

.contact-modal-instruction {
	margin: 0 20px 15px 20px;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: #0c243f;
}


.hubspot-form-container {
  max-width: 100% !important;
  overflow-y: auto;
}

.hubspot-form-container::-webkit-scrollbar {
  width: 5px;
}

.hubspot-form-container::-webkit-scrollbar-track {
  width: 25%;
  background: #4b99be40;
}

.hubspot-form-container::-webkit-scrollbar-thumb {
  background-color: #95d2ec;
  border: 1px solid transparent;
}

@media screen and (max-width: 992px) {
	.pricing-body {
		margin-bottom: 35px;
	}

	.pricing-body .header {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		color: #ffffff;
		margin: 35px 15px 30px 15px;
	}

	.MuiToggleButtonGroup-root {
		margin: 0 30px 25px 30px !important;
    
	}

	.MuiToggleButton-root {
		max-width: 175px;
		padding: 5px 30px !important;
		color: #fff !important;
		font-size: 15px !important;
		font-weight: 400 !important;
		line-height: 15px !important;
		border: 0px !important;
		text-transform: unset !important;
    
	}

	.MuiToggleButtonGroup-root .Mui-selected,
	.MuiToggleButtonGroup-root .Mui-selected:hover {
		background-color: #91d465 !important;
		color: #0c243f !important;
		font-weight: 700 !important;
		border-radius: 68px !important;
    font-family: "Montserrat" !important;
	}

	.member-count-text {
		font-size: 15px;
		text-align: center;
		margin-bottom: 15px;
	}

	.member-count-container {
		flex-direction: column;
		margin-top: 0px;
	}

	.plan-card-container {
		padding-bottom: 15px;
	}

	.contact-container {
		margin-top: 0;
	}

	.contact-container .contact-text {
		font-size: 18px;
		margin: 0 15px 20px 15px;
	}

	.plan-card-container .header-sub-text {
		max-width: 320px;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.plan-card-container .custom-plan-text {
		max-width: 320px;
		padding: 15px 20px 35px 20px;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		text-align: center;
		color: #91d465;
	}

	/* .tier-pricing-input {
    padding: 8px 10px;
  } */
}
