.checkout-section-title {
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-family: Montserrat-Bold;
  background-color: rgba(0, 0, 0, 0.25);
}

.checkout-wrapper {
  padding: 10px;
  justify-content: center;
}

.order-summary-wrapper {
  border: 1px solid #fff;
  min-width: 370px;
  max-width: 400px;
}

#order-title {
  font-size: 19px !important;
  padding: 5px 20px;
  z-index: 1;
  margin-bottom: -19px;
  font-family: Montserrat-Medium;
  font-weight: 600 !important;
  /* background-color: #0B2541; */
  background: linear-gradient(90deg, #0b2540 30%, #092843 70%);
}

.order-summary-inner-container {
  padding: 50px 30px;
  text-align: center;
}

.plan-title-header {
  font-size: 65px;
  color: #fff;
  font-family: Montserrat-Bold;
}

.users-text {
  color: #fff;
  font-family: Montserrat-Bold;
}

.checkout-form .member-count-container {
  margin: 60px;
}

.plan-sub-title {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.plus-sign {
  color: #91D465;
}

.plan-price {
  color: #91D465;
  font-size: 30px;
  font-family: Montserrat-Bold;
  margin-bottom: 0px;
}

.price-unit-text {
  font-size: 18px;
  vertical-align: baseline;
}

.price-dollar-text {
  font-size: 60px;
  vertical-align: top;
}

.price-cent-text {
  font-size: 30px;
  vertical-align: sub;
}

.mild-grey-text {
  color: rgba(255, 255, 255, 1);
}

.total-container {
  background-color: #91D465;
  text-align: center;
  font-family: Montserrat-Bold;
  padding: 10px;
  font-size: 26px;
}

.pricing-spinner {
  color: #91D465;
  width: 25px !important; 
  height: 25px !important;
  font-size: 15px;
}

.checkout-wrapper .btn-form-secondary {
  padding: 5px 25px;
  border-radius: 20px !important;
}

.checkout-wrapper .button-text {
  font-family: Montserrat-Bold !important;
  font-size: 20px !important;
}

.checkout-form .border-text-field-label {
  color: #fff !important;
  margin-top: 10px;
}

.card-element {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #385B73;
  width: 100%;
  height: 38px;
  color: #fff;
}

.checkout-form input {
  font-family: Montserrat-Medium;
}

.expiration-element {
  width: 50%;
  margin-right: 5%;
}

.checkout-error-message {
  color: red;
  font-size: 15px;
}

.checkout-form .btn:disabled{
  background-color: rgba(56, 90, 114, 1) !important;
  color: rgba(0, 63, 96, 1) !important;
  border: none !important;
}

.borderless-table {
  border: 1px solid #fff;
  padding: 15px;
  width: fit-content;
  display: inline-block;
}

.borderless-table td {
  border: none;
  vertical-align: middle;
}

.field-heading {
  font-family: Montserrat-Medium;
  font-size: 18px;
  padding: 5px;
  color: #fff;
  text-align: left;
}

.field-value {
  font-family: Montserrat-Medium;
  font-size: 18px;
  padding: 5px;
  color: #91d465;
  text-align: left;
}

@media (max-width:512px) {

	.order-summary-wrapper {
    min-width: 90%;
	}

  .order-summary-inner-container {
    padding: 25px;
  }

  .plan-sub-title {
    margin-bottom: 20px;
  }

  .checkout-form .member-count-container {
    margin: 40px;
  }

  .field-value {
    max-width: 200px;
  }
}