/*********************************************/
/*          CUSTOMIZED DEFAULTS         */
/*********************************************/
 
$body-bg: #fff;
$body-color: #455a64;
// $font-family-sans-serif: "Poppins",
// sans-serif;
// $primary: #000000;
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
